import React from "react";
import ProjectCard from "../ProjectCard";
// import OngoingList from "../OngoingList";
import { ongoingdata } from "./OngoingData";
import { completeddata } from "./CompletedData";

function Ongoing({ projectOnClick = null }) {
  return (
    <section className="section-pad border-bottom">
      <div className="container-xl container-fluid">
        <h3 className="section-title">Commercial Projects</h3>
        <div className="row justify-content-center">
          {/* <OngoingList
            RenderItem={props => (
              props.slug !== "emperors-pavilion" ? <div className="col-md-6 col-xl-4 mb-3">
                <ProjectCard onClick={projectOnClick} {...props} />
                </div> : <></>
              )}
            /> */}
          {ongoingdata?.map((item) => {
            return (
              <div className="col-md-6 col-xl-4 mb-3" key={item.id}>
                <ProjectCard onClick={projectOnClick} {...item} />
              </div>
            );
          })}
          {completeddata?.map((item) => {
            return (
              <div className="col-md-6 col-xl-4 mb-3" key={item.id}>
                <ProjectCard onClick={projectOnClick} {...item} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Ongoing;
