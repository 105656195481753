import React from "react";
import Layout from "../components/common/Layout";
import Banner from "../components/home/Banner";
import ProjectLogos from "../components/home/ProjectLogos";
import Testimonials from "../components/home/Testimonials";
import BlogSection from "../components/home/BlogSection";
import MediaSection from "../components/home/MediaSection";
import Ongoing from "../components/projects/Ongoing";
import OngoingCommercial from "../components/projects/commercial/Ongoing";

export default () => {
  return (
    <Layout>
      <Banner />
      <Ongoing />
      <OngoingCommercial />
      <ProjectLogos />
      <Testimonials />
      <BlogSection />
      <MediaSection />
    </Layout>
  );
};
