import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Item = ({ image = null, name = "", review = "" }) => {
  return (
    <div className="col-xl-6 col-lg-12">
      <div className="d-flex flex-wrap flex-sm-nowrap test-card wow fadeInDown">
        <div className="test-card-img">
          <Img className="w-100" fluid={image.childImageSharp.fluid} />
        </div>
        <div className="border-left pl-3 pr-3">
          <h5 className="font-weight-bold mt-2">{name}</h5>
          <p style={{ fontSize: "14px" }}>{review}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials = () => {
  const { allTestimonialsJson } = useStaticQuery(graphql`
    {
      allTestimonialsJson {
        edges {
          node {
            review
            name
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section className="section-pad border-bottom">
      <div className="container-lg container-fluid">
        <h3 className="section-title text-center">Testimonials</h3>
        <div className="row">
          {allTestimonialsJson.edges.map(({ node }, idx) => {
            return <Item {...node} key={idx} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
